import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

interface Props {
	data: {
		markdownRemark: {
			html: string;
			frontmatter: {
				date: string;
				desc: string;
				img: string;
				path: string;
				url: string;
				title: string;
			};
		};
	};
}

const BlogPost = ({ data }: Props) => {
	const post = {
		html: data.markdownRemark.html,
		...data.markdownRemark.frontmatter
	};

	const date = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(post.date));

	return (
		<Layout title={post.title} description={post.desc}>
			<Helmet>
				<meta property='og:url' content={post.url} />
				<meta property='og:type' content='Blog' />
				<meta property='og:image' content={post.img} />
				<meta property='og:image:alt' content='Blog Article Header.' />
				<meta property='og:site_name' content='ASGSR Students' />
				<meta property='og:locale' content='en_US' />
				<meta property='article:author' content='ASGSR Students' />
				<meta property='article:published_time' content={post.date} />
			</Helmet>

			<div className='col-md-9 mx-md-auto px-3'>
				<h1 className='mt-3 display-2 text-md-center raleway-700'>{post.title}</h1>
				<p className='mb-3 px-3 text-end text-secondary'>Published on: {date}</p>

				<article dangerouslySetInnerHTML={{ __html: post.html }}></article>
			</div>
		</Layout>
	);
};

export default BlogPost;

export const pageQuery = graphql`
	query BlogPostByPath($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				date
				path
				title
			}
		}
	}
`;
